import React from 'react';
import { useFormikContext } from 'formik';

import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import EmailGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/EmailGenericTemplatesEditor';
import NoteGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/NoteGenericTemplateEditor';
import NotificationGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/NotificationGenericTemplateEditor';
import SmsGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/SmsGenericTemplatesEditor';
import { buildGetTemplateTokensQueryParams } from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/utils';
import type { TemplateTypeKey } from '~/components/GenericTemplates/types';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import type { SuggestionToken } from '~/components/SystemConfiguration/SystenConfigurationTypes';
import useDataFetcher from '~/components/useDataFetcher';

const EDITOR_COMPONENT_BY_TEMPLATE_TYPE = {
  email: {
    component: EmailGenericTemplatesEditor,
  },
  note: {
    component: NoteGenericTemplatesEditor,
  },
  sms: {
    component: SmsGenericTemplatesEditor,
  },
  notification: {
    component: NotificationGenericTemplatesEditor,
  },
  // TODO: Enable this in NGTPA-17181
  document: {
    component: null,
  },
} as const;

interface TemplateBuilderEditorProps {
  templateType: TemplateTypeKey;
  titleTemplate?: string;
  bodyTemplate: string;
}

// these are only partial values
interface FormValues {
  lobs: string[];
  is_claim_level: boolean;
}

export const TemplateBuilderEditor: React.FC<TemplateBuilderEditorProps> = ({
  templateType,
  titleTemplate,
  bodyTemplate,
}) => {
  const { setFieldValue, isSubmitting, values } = useFormikContext<FormValues>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();

  const { data, isLoading } = useDataFetcher(
    `/api/v1/generic_templates/organization/${organization.id}/template/tokens?${buildGetTemplateTokensQueryParams(
      values
    )}`,
    {}
  );

  const handleChange = (value: string, id: string) => {
    setFieldValue(id, value);
  };

  const Component = EDITOR_COMPONENT_BY_TEMPLATE_TYPE[templateType].component;
  if (!Component) {
    return null;
  }

  // Need to unmount and remount editor component so suggestions will get updated
  if (!data || isLoading) {
    return <SkeletonTable rowsCount={5} columnsCount={1} maxHeight="100px" />;
  }
  return (
    <Component
      handleChange={handleChange}
      disabled={isSubmitting}
      titleTemplate={titleTemplate}
      bodyTemplate={bodyTemplate}
      suggestions={
        data?.tokens && !isLoading
          ? data.tokens.map((token: SuggestionToken) => ({
              label: token.display_name,
              id: token.token_key,
            }))
          : []
      }
    />
  );
};
