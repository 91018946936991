import React from 'react';

import { useStyles } from '~/assets/styles';
import { OverflowArrayTextDisplayWithTooltip, SortableTable, Text } from '~/components/core';
import Chip from '~/components/core/Atomic/Chip/Chip';
import Grid from '~/components/core/Atomic/Grid/Grid';
import { useLobConfiguration } from '~/components/hooks/useLobConfiguration';
import { PencilIcon } from '~/components/icons';
import InlineIconButton from '~/components/InlineIconButton';
import useOrganization from '~/components/OrganizationContext';
import { serverDateToLocal } from '~/DateTimeUtils';
import { getLobDescription } from '~/Utils/lobUtils';

import type { IncidentConfigurationSummary, IncidentConfigurationSummaryList } from './types';

interface ConfigurationsTableProps {
  incidentConfigSummaries: IncidentConfigurationSummaryList;
  onEditIncidentConfig: (id: number) => void;
  trackMixpanelEvent: (incidentConfiguration: IncidentConfigurationSummary) => void;
  counterSuffix: string;
  displayName: string;
}

const ConfigurationsTable: React.FC<ConfigurationsTableProps> = ({
  incidentConfigSummaries,
  onEditIncidentConfig,
  trackMixpanelEvent,
  counterSuffix,
  displayName,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { subOrganizationEnabled, subOrganizations } = useOrganization();
  const { lobConfigurationsDict } = useLobConfiguration();
  const Counter = () => (
    <Text
      weight={Text.WEIGHTS.SEMI_BOLD}
      variant={Text.VARIANTS.SM}
    >{`${incidentConfigSummaries?.length} ${counterSuffix}`}</Text>
  );

  const actionsCell = ({ onEdit }: { onEdit: (row: IncidentConfigurationSummary) => void }) => ({
    id: 'actions',
    label: 'Actions',
    disableSort: true,
    width: '70px',
    specialCell: (row: IncidentConfigurationSummary) => (
      <div>
        <InlineIconButton
          icon={PencilIcon}
          tooltipTitle="Edit"
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => {
            onEdit(row);
            trackMixpanelEvent(row);
          }}
          wrapWithSpan
        />
      </div>
    ),
  });

  const columns = [
    {
      id: 'display_name',
      label: displayName,
    },
    {
      id: 'sub_organization_id',
      label: 'Sub Organization',
      isHidden: !subOrganizationEnabled,
      specialCell: ({ sub_organization_id }: { sub_organization_id: number }) => (
        <div className="min-w-80">
          <OverflowArrayTextDisplayWithTooltip
            value={[subOrganizations.find((so: { id: number }) => so.id === sub_organization_id).name]}
            renderItem={(label) => (
              <span key={label}>
                <Chip label={label} size="small" />
              </span>
            )}
          />
        </div>
      ),
      disableSort: true,
    },
    {
      id: 'lob',
      label: 'Line of Business',
      specialCell: ({ lob }: { lob: string }) => (
        <div>
          <Chip label={getLobDescription(lob, lobConfigurationsDict)} size="small" />
        </div>
      ),
      disableSort: true,
    },
    {
      id: 'last_updated_datetime',
      label: 'Last Updated',
      specialCell: ({ last_updated_datetime }: { last_updated_datetime: string }) =>
        serverDateToLocal(last_updated_datetime),
    },
    {
      id: 'last_updated_by_username',
      label: 'Updated By',
    },
    actionsCell({
      onEdit: ({ id }: { id: number }) => onEditIncidentConfig(id),
    }),
  ];

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid>
          <Grid item xs={12} className="mr-2.5">
            <Counter />
          </Grid>
        </Grid>
      </Grid>

      <SortableTable columns={columns} rows={incidentConfigSummaries} stickyHeader maxHeight="50vh" />
    </>
  );
};

export default ConfigurationsTable;
