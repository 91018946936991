import { flatten, get, isEmpty, set, startCase, uniq, unset } from 'lodash';

import { getObjectPaths } from '~/Utils';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

const getCategoryIdsUsedInSubTypes = (values) => {
  const categories = [];
  const incidentTypes = get(values, 'configuration.incident_details.types', []);
  incidentTypes.forEach((type) => {
    const subTypes = get(type, 'sub_types', []);
    subTypes.forEach((subType) => {
      if (subType.category) {
        categories.push(subType.category);
      }
    });
  });

  return categories;
};

const getCategoryIdsUsedFields = (values) => {
  const categories = [];
  const categoriesPaths = getObjectPaths(values)?.filter((path) => path.includes('.incident_sub_type_categories'));

  categoriesPaths.forEach((path) => {
    const fieldCategories = get(values, path, []);
    if (!isEmpty(fieldCategories)) {
      categories.push(fieldCategories);
    }
  });

  return uniq(flatten(categories));
};

const migrateCategoriesOnTheFly = (values) => {
  const categoriesSection = get(values, 'configuration.categories', []);
  const categoriesIds = categoriesSection?.map((category) => category.id);
  const categoriesUsedInSubTypes = getCategoryIdsUsedInSubTypes(values);
  const categoriesUsedFields = getCategoryIdsUsedFields(values);
  const categoriesIdsInUsed = uniq(flatten([...categoriesUsedFields, ...categoriesUsedInSubTypes]));

  categoriesIdsInUsed.forEach((categoryId) => {
    if (!categoriesIds.includes(categoryId)) {
      categoriesSection.push({ id: categoryId, desc: startCase(categoryId) });
    }
  });

  return { ...values, configuration: { ...values.configuration, categories: categoriesSection } };
};

const staticWitnessesFields = {
  contact_ref_id: {
    id: 'contact_ref_id',
    desc: 'Witness',
    type: 'contact',
    active: true,
    mandatory: false,
  },
  description: {
    id: 'description',
    desc: 'Description',
    type: 'string',
    active: true,
    mandatory: false,
  },
};

const addStaticFields = (values) => {
  Object.keys(staticWitnessesFields).forEach((fieldId) => {
    if (!get(values, `configuration.witnesses.${fieldId}`)) {
      set(values, `configuration.witnesses.${fieldId}`, staticWitnessesFields[fieldId]);
    }
  });
  return values;
};

const ICD_CODE_CONFIG = {
  icd_codes: {
    type: 'pre_defined_fields',
    desc: 'Icd Codes',
    geo_specific: 'US',
    active: true,
    mandatory: false,
    dynamic: true,
  },
};

const INSURED_FIELDS_CONFIG = {
  insurer_contact_id: {
    type: 'contact',
    desc: 'Adverse Carrier',
    active: false,
    mandatory: false,
    accepted_roles: ['insurer'],
  },
  insurer_reference_number: {
    type: 'string',
    desc: 'Adverse Carrier Reference Number',
    active: false,
    mandatory: false,
  },
  insurer_policy_number: {
    type: 'string',
    desc: 'Adverse Carrier Policy Number',
    active: false,
    mandatory: false,
  },
  injury_date: {
    desc: 'Injury Date',
    type: 'date',
    active: false,
    mandatory: false,
  },
};

const migrateIcdCodesFields = (values) => {
  const isUsRegion = isLocaleRegionIsUs();
  if (!isUsRegion) {
    return values;
  }

  const INVOLVED_PARTIES = 'configuration.involved_parties';
  const autoInvolvedToMigrate = ['involved_person', 'involved_driver', 'involved_passenger', 'involved_non_motorist'];
  const involvedPartiesKeys = Object.keys(get(values, INVOLVED_PARTIES, {}));

  if (values.lob === 'auto_claim') {
    autoInvolvedToMigrate.forEach((partyKey) => {
      for (const fieldKey in ICD_CODE_CONFIG) {
        if (!get(values, `${INVOLVED_PARTIES}.${partyKey}.${fieldKey}`)) {
          set(values, `${INVOLVED_PARTIES}.${partyKey}.${fieldKey}`, ICD_CODE_CONFIG[fieldKey]);
        }
      }
    });

    return values;
  }

  involvedPartiesKeys.forEach((partyKey) => {
    for (const fieldKey in ICD_CODE_CONFIG) {
      const bodilyInjurySection = get(values, `${INVOLVED_PARTIES}.${partyKey}.bodily_injury_loss`);
      if (bodilyInjurySection && !get(values, `${INVOLVED_PARTIES}.${partyKey}.bodily_injury_loss.${fieldKey}`)) {
        set(values, `${INVOLVED_PARTIES}.${partyKey}.bodily_injury_loss.${fieldKey}`, ICD_CODE_CONFIG[fieldKey]);
      }
    }
  });

  return values;
};

const migrateInvolvedInsuredFields = (values) => {
  const INVOLVED_PARTIES = 'configuration.involved_parties';
  const involvedPartiesKeys = Object.keys(get(values, INVOLVED_PARTIES, {}));

  involvedPartiesKeys.forEach((partyKey) => {
    for (const fieldKey in INSURED_FIELDS_CONFIG) {
      if (!get(values, `${INVOLVED_PARTIES}.${partyKey}.${fieldKey}`)) {
        set(values, `${INVOLVED_PARTIES}.${partyKey}.${fieldKey}`, INSURED_FIELDS_CONFIG[fieldKey]);
      }
    }
  });

  return values;
};

const migrateNewFields = (values) => {
  values = migrateInvolvedInsuredFields(values);
  values = migrateIcdCodesFields(values);
  return values;
};

const migrateInheritAutoInvolvedFields = (values) => {
  if (!values.lob === 'auto_claim') {
    return values;
  }

  const involvedParties = get(values, 'configuration.involved_parties', {});
  const involvedProperty = get(values, 'configuration.involved_parties.involved_property', {});
  const involvedPerson = get(values, 'configuration.involved_parties.involved_person', {});

  Object.keys(involvedParties).forEach((partyKey) => {
    const involvedEntity = involvedParties[partyKey];
    if (involvedEntity?.inherit === 'involved_property') {
      Object.keys(involvedProperty).forEach((fieldKey) => {
        if (!get(involvedEntity, fieldKey)) {
          set(values, `configuration.involved_parties.${partyKey}.${fieldKey}`, involvedProperty[fieldKey]);
        }
      });
    }
    if (involvedEntity?.inherit === 'involved_person') {
      Object.keys(involvedPerson).forEach((fieldKey) => {
        if (!get(involvedEntity, fieldKey)) {
          set(values, `configuration.involved_parties.${partyKey}.${fieldKey}`, involvedPerson[fieldKey]);
        }
      });
    }
  });

  return values;
};

export const removeStaticFields = (values) => {
  Object.keys(staticWitnessesFields).forEach((fieldId) => {
    if (get(values, `configuration.witnesses.${fieldId}`)) {
      unset(values, `configuration.witnesses.${fieldId}`);
    }
  });
  return values;
};

export const setOnTheFlyFnolConfigurationMigrations = (values) => {
  values = addStaticFields(values);
  values = migrateInheritAutoInvolvedFields(values);
  values = migrateNewFields(values);
  return migrateCategoriesOnTheFly(values);
};

export default setOnTheFlyFnolConfigurationMigrations;
