import React from 'react';
import { useFormikContext } from 'formik';

import StateMultiselectFormikProps from '~/components/core/Formik/StatesMultiselectFormik/StateMultiSelectFormik';
import CountryAutocompleteFormik from '~/components/CountryAutocompleteFormik';
import type { AdvancedSearchFilterTypes } from '~/components/GenericTemplates/types';
import { ADVANCED_TEMPLATES_SEARCH_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import { CoveragesMultiselectFormik } from '~/components/TPA/Coverages/CoveragesMultiselectFormik';
import LobMultiselectFormik from '~/components/TPA/LOB/LobMultiselectFormik';
import SubOrganizationMultiselectFormik from '~/components/TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getOrganizationCountryCode, isCountryWithStates, isFeatureEnabled, isOrganizationWithStates } from '~/Utils';
import cn from '~/Utils/cn';

const GenericTemplateAdvancedSearchFiltersFormik: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { supportedClaimTypes } = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const { values, isSubmitting } = useFormikContext<AdvancedSearchFilterTypes>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const multiCountryOrganization = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );

  const shouldDisplayStates = isOrganizationWithStates(userOrganization) || multiCountryOrganization;

  const subOrganizationIds = organization.sub_organizations.map((sub_org: { id: number }) => sub_org.id);
  let columnsAmount = 2;
  if (multiCountryOrganization) {
    columnsAmount += 1;
  }
  if (shouldDisplayStates) {
    columnsAmount += 1;
  }
  if (organization.sub_organizations_enabled) {
    columnsAmount += 1;
  }
  const gridColumnsClassName: Record<number, string> = {
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
  };
  return (
    <>
      <div className={cn('mb-32 grid gap-32', gridColumnsClassName[columnsAmount])}>
        <div>
          <CoveragesMultiselectFormik
            coveragesFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.COVERAGE_KEYS}
            lobs={supportedClaimTypes}
            label="Select Coverages"
            subOrganizationIds={subOrganizationIds}
            disabled={isSubmitting}
            disableGeneral
          />
        </div>
        {multiCountryOrganization ? (
          <CountryAutocompleteFormik
            id={ADVANCED_TEMPLATES_SEARCH_KEYS.COUNTRY}
            countriesConfigurationKey="contact_location"
            disabled={isSubmitting}
            statesFieldIdToResetOnChange={ADVANCED_TEMPLATES_SEARCH_KEYS.STATES}
          />
        ) : null}
        {shouldDisplayStates ? (
          <div>
            <StateMultiselectFormikProps
              statesFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.STATES}
              disabled={isSubmitting || !(multiCountryOrganization ? isCountryWithStates(values.country) : true)}
              country={
                multiCountryOrganization && isCountryWithStates(values.country)
                  ? values.country
                  : getOrganizationCountryCode(userOrganization)
              }
            />
          </div>
        ) : null}
        {subOrganizationIds.length > 0 ? (
          <div>
            <SubOrganizationMultiselectFormik
              subOrganizationsFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.SUB_ORGANIZATION_IDS}
              disabled={isSubmitting}
            />
          </div>
        ) : null}
        <div>
          <LobMultiselectFormik
            lobFieldId={ADVANCED_TEMPLATES_SEARCH_KEYS.LOBS}
            disabled={isSubmitting}
            subOrganizationIds={organization.sub_organizations_enabled ? subOrganizationIds : []}
          />
        </div>
      </div>
    </>
  );
};

export default GenericTemplateAdvancedSearchFiltersFormik;
