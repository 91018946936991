import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import Button from '~/components/core/Atomic/Buttons/Button';
import ButtonsContainer from '~/components/core/Atomic/Buttons/ButtonsContainer';
import CancelButton from '~/components/core/Buttons/CancelButton';
import SwitchFormik from '~/components/core/Formik/SwitchFormik';
import type { PaymentsConfiguration } from '~/components/SystemConfiguration/Tabs/PaymentsConfiguration/types';
import { MultiSelectTextFieldFormik, NumericTextFieldFormik } from '~/components/TextFieldFormik';
import SANCTION_IO_SANCTION_LISTS from '~/server_shared/generated-types/SANCTION_IO_SANCTION_LISTS';
import { reportAxiosError } from '~/Utils';

interface SanctionsScreenConfigurationDialogProps {
  paymentsConfiguration: PaymentsConfiguration;
  disabled?: boolean;
  onCancel: () => void;
  onSubmit: (values: SanctionsIoConfiguration) => Promise<void>;
}

type SanctionsIoConfiguration = Pick<
  PaymentsConfiguration,
  | 'enable_sanctions_io_integration'
  | 'sanctions_io_include_address'
  | 'sanctions_io_min_score'
  | 'sanctions_io_data_sources'
>;

const sanctionsIoValidationSchema: Yup.SchemaOf<SanctionsIoConfiguration> = Yup.object({
  enable_sanctions_io_integration: Yup.boolean().required('Required'),
  sanctions_io_include_address: Yup.boolean().required('Required'),
  sanctions_io_min_score: Yup.number()
    .required('Required')
    .min(0, 'Minimum score must be 0.')
    .max(1, 'Maximum score must be 1'),
  sanctions_io_data_sources: Yup.array(),
});
const SanctionsScreenConfigurationDialog: React.FC<SanctionsScreenConfigurationDialogProps> = ({
  paymentsConfiguration,
  disabled = false,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const initialValues: SanctionsIoConfiguration = {
    enable_sanctions_io_integration: true,
    sanctions_io_include_address: paymentsConfiguration.sanctions_io_include_address || false,
    sanctions_io_min_score: paymentsConfiguration.sanctions_io_min_score || 0.85,
    sanctions_io_data_sources: paymentsConfiguration.sanctions_io_data_sources || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={sanctionsIoValidationSchema}
      enableReinitialize
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await onSubmit(values);
        } catch (error) {
          reportAxiosError(error);
          setSubmitting(false);
        }
      }}
    >
      {({ handleSubmit }) => {
        return (
          <CardDialog
            title="SanctionsIo Configuration"
            isDialog
            onClose={onCancel}
            preventClose={disabled}
            maxWidth="xs"
          >
            <div>
              <div>
                <MultiSelectTextFieldFormik
                  id="sanctions_io_data_sources"
                  label="Data sources"
                  options={SANCTION_IO_SANCTION_LISTS.map((l) => l.short_name)}
                  renderValue={(selected: string[]) => selected.join(', ')}
                  className={classes.textField}
                  fullWidth
                  renderOption={(option: (typeof SANCTION_IO_SANCTION_LISTS)[number]['short_name']) =>
                    SANCTION_IO_SANCTION_LISTS.find((l) => l.short_name === option)?.long_name || ''
                  }
                />
              </div>
              <div>
                <SwitchFormik
                  className={classes.formsSwitch}
                  id="sanctions_io_include_address"
                  label="Include address in search"
                />
              </div>
              <div>
                <NumericTextFieldFormik
                  id="sanctions_io_min_score"
                  className={classes.textField}
                  label="score"
                  decimalScale={2}
                />
              </div>
            </div>
            <ButtonsContainer>
              <CancelButton disabled={disabled} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={disabled} onClick={() => handleSubmit()}>
                Save
              </Button>
            </ButtonsContainer>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

export default SanctionsScreenConfigurationDialog;
