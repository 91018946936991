import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

import CardDialog from '~/components/CardDialog';
import { Text } from '~/components/core';
import AlertBanner from '~/components/core/AlertBanner';
import Button from '~/components/core/Atomic/Buttons/Button';
import CancelButton from '~/components/core/Buttons/CancelButton';
import StateMultiselectFormik from '~/components/core/Formik/StatesMultiselectFormik/StateMultiSelectFormik';
import CountryAutocompleteFormik from '~/components/CountryAutocompleteFormik';
import { TemplateBuilderEditor } from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/TemplateBuilderEditor';
import type { TemplateFormSubmitValuesProps, TemplateTypeKey } from '~/components/GenericTemplates/types';
import { TEMPLATE_BUILDER_FORM_KEYS, TEMPLATES_TYPES } from '~/components/GenericTemplates/utils/genericTemplatesUtils';
import { useCms } from '~/components/hooks/useCms';
import RadioButtonFormik from '~/components/RadioButtonFormik';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import TextFieldFormik from '~/components/TextFieldFormik';
import { CoveragesMultiselectFormik } from '~/components/TPA/Coverages/CoveragesMultiselectFormik';
import { LobCheckboxMultiselectFormik } from '~/components/TPA/LOB/LobCheckboxMultiselectFormik';
import SubOrganizationMultiselectFormik from '~/components/TPA/SubOrganizations/SubOrganizationMultiselectFormik';
import colors from '~/theme/tailwind/colors';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { getOrganizationCountryCode, isCountryWithStates, isFeatureEnabled, isOrganizationWithStates } from '~/Utils';

interface GenericTemplateBuilderContainerProps {
  templateType: TemplateTypeKey;
  handleClose: () => void;
  handleSubmit: (values: TemplateFormSubmitValuesProps) => void;
  initialValues?: TemplateFormSubmitValuesProps;
  isEdit?: boolean;
  connectedAutomaticRuleActionsDisplayNames?: string[];
}

const GenericTemplateBuilderContainer: React.FC<GenericTemplateBuilderContainerProps> = ({
  templateType,
  handleClose,
  handleSubmit,
  initialValues,
  isEdit = false,
  connectedAutomaticRuleActionsDisplayNames,
}) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [confirmDialogCallback, setConfirmDialogCallback] = React.useState<(() => void) | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const multiCountryOrganization = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );

  const shouldDisplayStates = isOrganizationWithStates(userOrganization) || multiCountryOrganization;

  const DEFAULT_FORM_VALUES: TemplateFormSubmitValuesProps = {
    is_all_suborgs: false,
    sub_organization_ids: [],
    is_all_lobs: true,
    lobs: [],
    coverage_keys: [],
    is_all_coverages: true,
    country: multiCountryOrganization ? '' : getOrganizationCountryCode(userOrganization),
    states: [],
    is_all_states: false,
    is_enabled: true,
    template_name: '',
    template_type: templateType,
    is_claim_level: true,
    body_template: '',
    title_template: '',
  };

  const getSubOrgsIdsByTemplateContext = (isAllSubOrgs: boolean, subOrgsIds: number[]): number[] => {
    if (!organization.sub_organizations_enabled) return [];
    if (isAllSubOrgs) return organization.sub_organizations.map((subOrg: { id: number }) => subOrg.id);
    return subOrgsIds;
  };

  const isConnectedToAutomaticRule =
    connectedAutomaticRuleActionsDisplayNames && connectedAutomaticRuleActionsDisplayNames?.length > 0;
  return (
    <>
      <CardDialog
        isDialog
        title={`${isEdit ? 'Edit ' : ''}${TEMPLATES_TYPES[templateType]?.display_name} Template`}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        {isConnectedToAutomaticRule ? (
          <AlertBanner
            className="mb-20"
            title="Context editing is disabled"
            alertType="info"
            note={`Editing the context is disabled because this template is connected to the following automatic rules: ${connectedAutomaticRuleActionsDisplayNames.join(
              ', '
            )}`}
          />
        ) : null}
        <Formik
          initialValues={initialValues ? initialValues : DEFAULT_FORM_VALUES}
          validationSchema={Yup.object().shape({
            [TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_SUBORGS]: Yup.boolean(),
            [TEMPLATE_BUILDER_FORM_KEYS.SUB_ORGANIZATION_IDS]: Yup.array()
              .of(Yup.number())
              .when('is_all_suborgs', {
                is: (is_all_suborgs: boolean) => !is_all_suborgs && organization.sub_organizations_enabled,
                then: Yup.array().of(Yup.number()).min(1, 'At least 1 sub-organization is required'),
              }),
            [TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_LOBS]: Yup.boolean(),
            [TEMPLATE_BUILDER_FORM_KEYS.LOBS]: Yup.array()
              .of(Yup.string())
              .when('is_all_lobs', {
                is: false,
                then: Yup.array().of(Yup.string()).min(1, 'At least 1 line of business is required'),
              }),
            [TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_COVERAGES]: Yup.boolean(),
            [TEMPLATE_BUILDER_FORM_KEYS.COVERAGE_KEYS]: Yup.array()
              .of(Yup.string())
              .when('is_all_coverages', {
                is: false,
                then: Yup.array().of(Yup.string()).min(1, 'At least 1 coverage is required'),
              }),
            [TEMPLATE_BUILDER_FORM_KEYS.STATES]: Yup.array().test(
              'states-required',
              'At least 1 state is required',
              (value, context) => {
                const country: [] = context.parent[TEMPLATE_BUILDER_FORM_KEYS.COUNTRY];
                const is_all_states: [] = context.parent[TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_STATES];
                return !(isCountryWithStates(country) && !is_all_states) || (value !== undefined && value.length > 0);
              }
            ),
            [TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_STATES]: Yup.boolean(),
            [TEMPLATE_BUILDER_FORM_KEYS.COUNTRY]: Yup.string().required('Required'),
            [TEMPLATE_BUILDER_FORM_KEYS.IS_ENABLED]: Yup.boolean(),
            [TEMPLATE_BUILDER_FORM_KEYS.TEMPLATE_NAME]: Yup.string().required('Required'),
            [TEMPLATE_BUILDER_FORM_KEYS.TEMPLATE_TYPE]: Yup.string().required('Required'),
            [TEMPLATE_BUILDER_FORM_KEYS.IS_CLAIM_LEVEL]: Yup.boolean().required('Required'),
            [TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE]: Yup.string().required('Required'),
            [TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE]: Yup.string()
              .nullable()
              .when('template_type', {
                is: (template_type: TemplateTypeKey) => TEMPLATES_TYPES[template_type]?.template_title_required,
                then: Yup.string().required('Required'),
              }),
          })}
          onSubmit={async (values: TemplateFormSubmitValuesProps, { setSubmitting }) => {
            try {
              await handleSubmit(values);
              handleClose();
            } catch (error) {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => {
            const templateFieldsEmpty =
              values[TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE] === '' &&
              values[TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE] === '';

            const handleLevelChange = (input_id: string, value: boolean) => {
              if (templateFieldsEmpty) {
                setFieldValue(input_id, value);
              } else {
                setConfirmDialogCallback(() => () => {
                  setFieldValue(input_id, value);
                  setFieldValue(TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE, '');
                  setFieldValue(TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE, '');
                });
              }
            };

            return (
              <>
                <div className="grid grid-cols-2 gap-12">
                  <div className="col-span-1 m-4">
                    <TextFieldFormik
                      id={TEMPLATE_BUILDER_FORM_KEYS.TEMPLATE_NAME}
                      label="Template Name"
                      className="mb-2 mt-2"
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </div>
                  {organization.sub_organizations_enabled ? (
                    <div className="col-span-2 m-4">
                      <SubOrganizationMultiselectFormik
                        subOrganizationsFieldId={TEMPLATE_BUILDER_FORM_KEYS.SUB_ORGANIZATION_IDS}
                        disabled={isConnectedToAutomaticRule || isSubmitting}
                        allSelectedFieldId={TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_SUBORGS}
                        selectAllLabel="All"
                        showAllInRenderedSelectedOptions
                        shouldDisplayAllOption
                      />
                    </div>
                  ) : null}
                  <div className="col-span-2 m-4">
                    <Text variant={Text.VARIANTS.SM}>Select line of business</Text>
                    <LobCheckboxMultiselectFormik
                      disabled={isConnectedToAutomaticRule}
                      handleChangeWrapper={(callback) => {
                        if (templateFieldsEmpty) {
                          callback();
                        } else {
                          setConfirmDialogCallback(() => {
                            return () => {
                              callback();
                              setFieldValue(TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE, '');
                              setFieldValue(TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE, '');
                            };
                          });
                        }
                      }}
                      lobsFieldId={TEMPLATE_BUILDER_FORM_KEYS.LOBS}
                      allSelectedFieldId={TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_LOBS}
                      subOrganizationIds={getSubOrgsIdsByTemplateContext(
                        values.is_all_suborgs,
                        values.sub_organization_ids
                      )}
                    />
                  </div>
                  <div className="col-span-2 m-2 ml-4">
                    <Text variant={Text.VARIANTS.SM}>Level</Text>
                    <RadioButtonFormik
                      id={TEMPLATE_BUILDER_FORM_KEYS.IS_CLAIM_LEVEL}
                      onChange={() => {
                        handleLevelChange(TEMPLATE_BUILDER_FORM_KEYS.IS_CLAIM_LEVEL, true);
                      }}
                      label="Claim"
                      optionValue={true}
                      disabled={isConnectedToAutomaticRule || isSubmitting}
                      size="small"
                      color={colors.teal['700']}
                    />
                  </div>
                  <div className="m-2 ml-4 grid grid-cols-2">
                    <div className="col-span-1 flex items-center">
                      <RadioButtonFormik
                        onChange={() => {
                          handleLevelChange(TEMPLATE_BUILDER_FORM_KEYS.IS_CLAIM_LEVEL, false);
                        }}
                        id={TEMPLATE_BUILDER_FORM_KEYS.IS_CLAIM_LEVEL}
                        label="Exposure"
                        optionValue={false}
                        disabled={isConnectedToAutomaticRule || isSubmitting}
                        size="small"
                        color={colors.teal['700']}
                      />
                    </div>
                    <div className="col-span-1">
                      <CoveragesMultiselectFormik
                        coveragesFieldId={TEMPLATE_BUILDER_FORM_KEYS.COVERAGE_KEYS}
                        lobs={values.is_all_lobs || !values.lobs ? [] : values.lobs}
                        label="Select Coverages"
                        subOrganizationIds={getSubOrgsIdsByTemplateContext(
                          values.is_all_suborgs,
                          values.sub_organization_ids
                        )}
                        disabled={
                          isConnectedToAutomaticRule ||
                          isSubmitting ||
                          values.is_claim_level ||
                          values.is_all_suborgs ||
                          values.is_all_lobs
                        }
                        disableGeneral
                        allCoveragesFieldId={TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_COVERAGES}
                        showAllInRenderedSelectedOptions
                        shouldDisplayAll
                      />
                    </div>
                    {multiCountryOrganization ? (
                      <div className="col-span-2 m-2 ml-4">
                        <CountryAutocompleteFormik
                          id={TEMPLATE_BUILDER_FORM_KEYS.COUNTRY}
                          countriesConfigurationKey="contact_location"
                          disabled={isConnectedToAutomaticRule || isSubmitting}
                          options={multiCountryOrganization ? undefined : [DEFAULT_FORM_VALUES.country]}
                          statesFieldIdToResetOnChange={TEMPLATE_BUILDER_FORM_KEYS.STATES}
                        />
                      </div>
                    ) : null}
                    {shouldDisplayStates ? (
                      <div className="col-span-2 m-2 ml-4">
                        <StateMultiselectFormik
                          statesFieldId={TEMPLATE_BUILDER_FORM_KEYS.STATES}
                          allSelectedFieldId={TEMPLATE_BUILDER_FORM_KEYS.IS_ALL_STATES}
                          disabled={
                            isConnectedToAutomaticRule ||
                            isSubmitting ||
                            !(multiCountryOrganization ? isCountryWithStates(values.country) : true)
                          }
                          showAllInRenderedSelectedOptions
                          shouldDisplayAllOption
                          country={
                            multiCountryOrganization && isCountryWithStates(values.country)
                              ? values.country
                              : getOrganizationCountryCode(userOrganization)
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="my-2 ml-4 mr-2">
                  <TemplateBuilderEditor
                    templateType={templateType}
                    titleTemplate={values.title_template}
                    bodyTemplate={values.body_template}
                  />
                </div>

                <div className="mt-16 flex w-full justify-end">
                  <CancelButton disabled={isSubmitting} onClick={handleClose} />
                  <Button variant="contained" color="primary" disabled={isSubmitting} onClick={() => handleSubmit()}>
                    Save
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </CardDialog>
      {confirmDialogCallback ? (
        <CardDialog isDialog title="Confirm Selection Change" onClose={handleClose} fullWidth maxWidth="sm">
          <p>Are you sure you want to change your selection? Changing it will erase the existing template draft</p>
          <div className="mt-16 flex w-full justify-end">
            <CancelButton
              onClick={() => {
                setConfirmDialogCallback(undefined);
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('confirm');
                confirmDialogCallback();
                setConfirmDialogCallback(undefined);
              }}
            >
              Confirm
            </Button>
          </div>
        </CardDialog>
      ) : null}
    </>
  );
};

export default GenericTemplateBuilderContainer;
