import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Switch, withStyles } from '@material-ui/core';
import axios from 'axios';
import { Formik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import Grid from '~/components/core/Atomic/Grid/Grid';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import EmptyState from '~/components/core/EmptyState';
import { AddIcon } from '~/components/deprecatedMuiIcons';
import { isLocaleRegionIsUs } from '~/Utils/regionUtils';

import { CATASTROPHE_TYPES, COUNTRY_TO_STATE_MAP } from '../../Types';
import { reportAxiosError } from '../../Utils';
import CardDialog from '../CardDialog';
import { SortableTable } from '../core';
import { PencilIcon } from '../icons';
import InlineIconButton from '../InlineIconButton';
import LoadingIndicator from '../LoadingIndicator';
import useOrganization from '../OrganizationContext';
import { DatePickerTextFieldFormik, MultiSelectTextFieldFormik, TextFieldFormik } from '../TextFieldFormik';
import useDataFetcher from '../useDataFetcher';

import OperationsBreadcrumbs from './OperationsBreadcrumbs';
import { useSysconfig } from './SystemConfigurationScreen';

import styles, { useStyles } from '../../assets/styles';

const CatEventsTable = () => {
  const { organization } = useSysconfig();
  const {
    isLoading,
    isError,
    data: catEvents,
    reloadData: reloadCatEvents,
  } = useDataFetcher(`/api/v1/organizations/${organization.id}/cat_events`);
  const classes = useStyles();
  const [addCatEventDialogOpen, setAddCatEventDialogOpen] = useState(false);
  const [catEventToEdit, setCatEventToEdit] = useState(null);
  const [catMarkDisablePopupOpen, setCatMarkDisablePopupOpen] = useState(false);
  const [catEnablingSubmitting, setCatEnablingSubmitting] = useState(false);
  const {
    isLoading: catConfigLoading,
    isError: catConfigError,
    data: catConfig,
    reloadData: reloadCatConfig,
  } = useDataFetcher(`/sysconfig/api/v1/organizations/${organization.id}/cat_config`);
  const { reloadOperationalDetails } = useOrganization();

  if (isLoading || isError || catConfigLoading || catConfigError) {
    return <LoadingIndicator isError={isError ? isError : catConfigError} />;
  }

  const enableCatEvents = async () => {
    try {
      setCatEnablingSubmitting(true);
      const requestedCatEnableStatus = { status: !catConfig.enable_mark_as_catastrophe };
      await axios.put(`/sysconfig/api/v1/organizations/${organization.id}/cat_config`, requestedCatEnableStatus);
      await reloadCatConfig();
      await reloadOperationalDetails();
    } catch (error) {
      await reportAxiosError(error);
    } finally {
      setCatEnablingSubmitting(false);
    }
  };

  const handleCloseDialog = () => {
    setAddCatEventDialogOpen(false);
    setCatEventToEdit(null);
  };

  const handleOpenDialog = (catEvent) => {
    if (catEvent) {
      setCatEventToEdit(catEvent);
    }
    setAddCatEventDialogOpen(true);
  };

  const handleCreateCatEvent = async (values) => {
    try {
      await axios.post(`/api/v1/organizations/${organization.id}/cat_events`, values);
      await reloadCatEvents();
      handleCloseDialog();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  const handleUpdateCatEvent = async (values, catEventId) => {
    try {
      await axios.put(`/api/v1/organizations/${organization.id}/cat_events/${catEventId}`, values);
      await reloadCatEvents();
      handleCloseDialog();
    } catch (error) {
      await reportAxiosError(error);
      throw error;
    }
  };

  let columnData = [
    { id: 'name', label: 'Cat Name' },
    { id: 'code', label: 'Cat Code' },
    { id: 'pcs_number', label: 'PCS' },
    { id: 'types', label: 'Type', specialCell: (data) => (data.types ? data.types.join(', ') : '') },
    {
      id: 'start_date',
      label: 'Start Date',
      specialCell: (date) => (date.start_date ? moment(date.start_date).format('MM/DD/YYYY') : ''),
    },
    {
      id: 'end_date',
      label: 'End Date',
      specialCell: (date) => (date.end_date ? moment(date.end_date).format('MM/DD/YYYY') : ''),
    },
    { id: 'us_states', label: 'Location', specialCell: (data) => (data.us_states ? data.us_states.join(', ') : '') },
    {
      id: 'is_hidden',
      label: 'Show',
      specialCell: (data) => (
        <span>
          <Switch
            checked={!data.is_hidden}
            className={classes.formsSwitch}
            size="small"
            onChange={() => handleUpdateCatEvent({ ...data, is_hidden: !data.is_hidden }, data.id)}
          />
        </span>
      ),
    },
    { id: 'count', label: 'Number of claims' },
    {
      id: 'edit',
      label: 'Actions',
      disableSort: true,
      specialCell: (data) => (
        <InlineIconButton
          tooltipTitle="Edit"
          icon={PencilIcon}
          className={`${classes.textIcon} ${classes.marginedIcon} ${classes.hoverableNonFilledIcon}`}
          onClick={() => handleOpenDialog(data)}
          wrapWithSpan
        />
      ),
    },
  ];

  if (!isLocaleRegionIsUs()) {
    columnData = columnData.filter((column) => column.id !== 'us_states');
  }

  return (
    <>
      <CardDialog noCardTitle>
        <OperationsBreadcrumbs currentTab="Catastrophic Events" />
        <CardDialog title="CATASTROPHIC EVENTS">
          <Typography variant="body1">
            Add and manage catastrophic events for the ability to mark claim as catastrophe. You can choose whether to
            display the catastrophe in the marking catastrophe drop-down list.
          </Typography>
          <div className={classes.spaceBetweenButtonsContainer}>
            <span>
              <Switch
                checked={catConfig.enable_mark_as_catastrophe}
                className={classes.formsSwitch}
                disabled={catEnablingSubmitting}
                size="small"
                onChange={
                  catConfig.enable_mark_as_catastrophe ? () => setCatMarkDisablePopupOpen(true) : enableCatEvents
                }
              />
              <span>Enable “Mark as catastrophic event” on Summary details</span>
            </span>
            <Button color="primary" onClick={() => handleOpenDialog()}>
              <AddIcon className={classes.leftButtonIcon} />
              Add New Catastrophic Event
            </Button>
          </div>
          {catEvents.length > 0 ? <SortableTable columns={columnData} rows={catEvents} /> : <EmptyState />}
        </CardDialog>
        {addCatEventDialogOpen && (
          <AddAndEditCatEventDialogStyled
            onCreate={handleCreateCatEvent}
            onUpdate={handleUpdateCatEvent}
            onClose={handleCloseDialog}
            catEvent={catEventToEdit}
          />
        )}
        {catMarkDisablePopupOpen && (
          <DisableCatPopup
            onClose={() => setCatMarkDisablePopupOpen(false)}
            enableCatEvents={enableCatEvents}
            catEvents={catEvents}
          />
        )}
      </CardDialog>
    </>
  );
};

const DisableCatPopup = (props) => {
  const { onClose, enableCatEvents, catEvents } = props;
  const classes = useStyles();

  const hasIncidentsWithCat = () => {
    return catEvents.some((event) => event.count > 0);
  };

  return (
    <CardDialog title={'Disable “Mark as catastrophic event"'} isDialog maxWidth="xs" fullWidth onClose={onClose}>
      {hasIncidentsWithCat() ? (
        <>
          <Typography>
            Disabling &quot;Mark as catastrophic event&quot; is not available with open incidents which were previously
            marked as catastrophic event.
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" color="primary" onClick={onClose} className={classes.button}>
              Ok
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography>
            Are you sure you want to disable &quot;Mark as catastrophic event&quot;? Once you disable this feature
            marking an incident as a catastrophic event will not be available in the summary page.
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button variant="contained" onClick={onClose} className={classes.cancelButtonWithRoundMargin}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                enableCatEvents();
                onClose();
              }}
              className={classes.button}
            >
              Yes, disable
            </Button>
          </div>
        </>
      )}
    </CardDialog>
  );
};

DisableCatPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  enableCatEvents: PropTypes.func.isRequired,
  catEvents: PropTypes.array.isRequired,
};

const AddAndEditCatEventDialog = (props) => {
  const { onClose, onCreate, onUpdate, catEvent, classes } = props;

  let initialValues = {
    name: '',
    code: '',
    pcs_number: '',
    types: [],
    start_date: '',
    end_date: '',
    us_states: [],
    is_hidden: false,
  };

  if (catEvent) {
    initialValues = { initialValues, ...catEvent };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Required'),
        code: Yup.string(),
        pcs_number: Yup.number().nullable(),
        types: Yup.array(),
        start_date: Yup.date().nullable(),
        end_date: Yup.date().nullable(),
        us_states: Yup.array(),
        is_hidden: Yup.boolean(),
      })}
      enableReinitialize
      onSubmit={async (values, formikProps) => {
        try {
          if (catEvent) {
            await onUpdate(values, catEvent.id);
          } else {
            await onCreate(values);
          }
        } catch (error) {
          formikProps.setSubmitting(false);
        }
      }}
    >
      {(formikProps) => {
        const { values, isSubmitting, handleSubmit, setFieldValue } = formikProps;

        return (
          <CardDialog
            title={catEvent ? 'Edit Catastrophic Event' : 'Add New Catastrophic Event'}
            isDialog
            preventClose={isSubmitting}
            maxWidth="sm"
            fullWidth
            onClose={onClose}
          >
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <TextFieldFormik
                  id="name"
                  label="Catastrophic Event Name"
                  fullWidth
                  className={classes.textField}
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <TextFieldFormik
                  id="code"
                  label="Cat Code (internal)"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <TextFieldFormik
                  id="pcs_number"
                  label="PCS"
                  className={classes.textField}
                  fullWidth
                  disabled={isSubmitting}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <MultiSelectTextFieldFormik
                  id="types"
                  label="Types"
                  className={classes.textField}
                  fullWidth
                  options={CATASTROPHE_TYPES}
                  withOptionChips
                  renderValue={() => ''}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={5}>
                <DatePickerTextFieldFormik
                  id="start_date"
                  label="Start Date"
                  className={classes.textField}
                  disableFuture
                  fullWidth
                  disabled={isSubmitting}
                  clearable
                />
              </Grid>
              <Grid item xs={5}>
                <DatePickerTextFieldFormik
                  id="end_date"
                  label="End Date"
                  className={classes.textField}
                  disableFuture
                  fullWidth
                  disabled={isSubmitting}
                  clearable
                />
              </Grid>
              {isLocaleRegionIsUs() && (
                <>
                  <Grid item xs={5}>
                    <MultiSelectTextFieldFormik
                      id="us_states"
                      label="States"
                      className={classes.textField}
                      fullWidth
                      options={Object.keys(COUNTRY_TO_STATE_MAP['US'])
                        .filter((value) => value !== '')
                        .sort((s1, s2) => COUNTRY_TO_STATE_MAP['US'][s1].localeCompare(COUNTRY_TO_STATE_MAP['US'][s2]))}
                      renderOption={(option) => COUNTRY_TO_STATE_MAP['US'][option]}
                      renderValue={() => ''}
                      withOptionChips
                    />
                  </Grid>
                  <Grid item xs={7} />
                </>
              )}
              <Grid item xs={12}>
                <Switch
                  value={!values.is_hidden}
                  onChange={() => setFieldValue('is_hidden', !values.is_hidden)}
                  className={classes.formsSwitch}
                  size="small"
                  checked={!values.is_hidden}
                />
                <span>
                  Show the catastrophe in the dropdown list under <i>Mark as Catastrophic event</i>
                </span>
              </Grid>
              <div className={classes.buttonsContainer}>
                <CancelButton disabled={isSubmitting} onClick={onClose} />
                <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Grid>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

AddAndEditCatEventDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  catEvent: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

const AddAndEditCatEventDialogStyled = withStyles(styles)(AddAndEditCatEventDialog);

export default CatEventsTable;
