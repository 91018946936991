import React from 'react';
import { isEmpty } from 'lodash';

import CardDialog from '~/components/CardDialog';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import SkeletonTable from '~/components/core/Skeletons/SkeletonTable';
import SortableTable from '~/components/core/Tables/SortableTable';
import Text from '~/components/core/TextComponents/Text';
import useCurrencyFormatter from '~/components/CurrencyFormatterContext';
import { useCms } from '~/components/hooks/useCms';
import useOrganization from '~/components/OrganizationContext';
import type { StatReserveConfiguration } from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationPage';
import StatReserveConfigurationSubRulesTable from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveConfigurationSubRulesTable';
import StatReserveUpsertDialog from '~/components/SystemConfiguration/ClaimConfiguration/StatReserveConfiguration/StatReserveUpsertDialog';
import { CONFIGURATION_FEATURES_NAMES } from '~/Types';
import { isFeatureEnabled, isOrganizationWithStates } from '~/Utils';

import PencilIcon from '../../../icons/PencilIcon';

export interface StatReserveConfigurationRowWithSortableId {
  id: number;
  statReserveConfiguration: StatReserveConfiguration;
}

export interface StatReserveConfigurationTableProps {
  statReserveConfigurationSortableRows?: StatReserveConfigurationRowWithSortableId[];
  coveragesSubOrganizationIdDict: Record<number, number[] | undefined>;
  reloadData: () => void;
  isLoading: boolean;
  isError: boolean;
}
const StatReserveConfigurationTable: React.FC<StatReserveConfigurationTableProps> = ({
  statReserveConfigurationSortableRows,
  coveragesSubOrganizationIdDict,
  reloadData,
  isError,
  isLoading,
}) => {
  const organization = useOrganization();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const subOrganizationEnabled = organization.subOrganizationEnabled;
  const { currencyFormatter } = useCurrencyFormatter();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { userOrganization } = useCms();
  const multiCountryOrganization = isFeatureEnabled(
    userOrganization,
    CONFIGURATION_FEATURES_NAMES.MULTI_COUNTRY_ORGANIZATION
  );
  const [selectedRow, setSelectedRow] = React.useState<StatReserveConfiguration | null>(null);

  const handleSubmit = () => {
    reloadData();
    setSelectedRow(null);
  };
  const handleClose = () => {
    setSelectedRow(null);
  };

  const handleEditClick = (row: StatReserveConfigurationRowWithSortableId) => {
    setSelectedRow(row.statReserveConfiguration);
  };
  const isLocationCondition = isOrganizationWithStates(userOrganization) || multiCountryOrganization;
  const columnData = [
    {
      id: StatReserveConfigurationTableColumns.coverage,
      label: StatReserveConfigurationTableColumns.coverage,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        row.statReserveConfiguration.coverage_display_name,
    },
    {
      id: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      label: StatReserveConfigurationTableColumns.defaultIndemnityReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        currencyFormatter.format(row.statReserveConfiguration.indemnity_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      label: StatReserveConfigurationTableColumns.defaultExpenseReserve,
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) =>
        currencyFormatter.format(row.statReserveConfiguration.expenses_reserve_amount),
    },
    {
      id: StatReserveConfigurationTableColumns.edit,
      label: '',
      numeric: false,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) => {
        return (
          <IconButton onClick={() => handleEditClick(row)} className="text-slate-900 hover:text-teal-700">
            <PencilIcon iconColor="currentColor" />
          </IconButton>
        );
      },
    },
    {
      id: StatReserveConfigurationTableColumns.subRules,
      label: '',
      numeric: false,
      expandable: true,
      isHidden: !subOrganizationEnabled && !isLocationCondition,
      specialCell: (row: StatReserveConfigurationRowWithSortableId) => {
        const subRulesReservesList = row.statReserveConfiguration.sub_rules ?? [];
        return (
          <StatReserveConfigurationSubRulesTable
            subRulesReservesList={subRulesReservesList}
            statReserveConfiguration={row}
            specificSubOrgIdOptions={coveragesSubOrganizationIdDict[row.statReserveConfiguration.coverage_config_id]}
            reloadData={reloadData}
          />
        );
      },
    },
  ];

  return (
    <div>
      <CardDialog title="Coverages">
        {isLoading || isError ? (
          <SkeletonTable columnsCount={4} rowsCount={7} isError={isError} />
        ) : !isEmpty(statReserveConfigurationSortableRows) ? (
          <SortableTable
            columns={columnData}
            rows={
              statReserveConfigurationSortableRows
                ? statReserveConfigurationSortableRows.sort((a, b) =>
                    a.statReserveConfiguration.coverage_display_name.localeCompare(
                      b.statReserveConfiguration.coverage_display_name
                    )
                  )
                : []
            }
            disableSortByUser={true}
            shouldRenderExpandAsTable={true}
          />
        ) : (
          <Text className="mt-40" variant={Text.VARIANTS.XL}>
            No coverages found for this line of business.
          </Text>
        )}
      </CardDialog>
      {selectedRow ? (
        <StatReserveUpsertDialog statReserveConfig={selectedRow} onSubmit={handleSubmit} onClose={handleClose} />
      ) : null}
    </div>
  );
};

enum StatReserveConfigurationTableColumns {
  coverage = 'Coverage',
  defaultIndemnityReserve = 'Default Indemnity Reserve',
  defaultExpenseReserve = 'Default Expense Reserve',
  subRules = 'SubRules',
  edit = 'Edit',
}

export default StatReserveConfigurationTable;
