import React from 'react';

import BaseGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/BaseGenericTemplatesEditor';
import type { TemplateBuilderEditorInnerComponentProps } from '~/components/GenericTemplates/types';

const EmailGenericTemplatesEditor: React.FC<TemplateBuilderEditorInnerComponentProps> = ({
  handleChange,
  disabled,
  titleTemplate,
  bodyTemplate,
  suggestions,
}) => {
  return (
    <BaseGenericTemplatesEditor
      titleHeading="Email Title"
      bodyHeading="Email Body"
      suggestions={suggestions}
      bodyTemplate={bodyTemplate}
      titleTemplate={titleTemplate}
      disabled={disabled}
      handleChange={handleChange}
      bodyFormatDisplayConfig={{ font: true, textColor: true, textStyle: true, align: true, link: true }}
    />
  );
};

export default EmailGenericTemplatesEditor;
