export const CONFIG_PER_VALUES = [
  { text: 'Organization', value: 'org' },
  { text: 'Sub-Organizations', value: 'suborgs' },
];

export const PAYMENTS_CONFIG_FIELDS = {
  ENABLE_PAYING_EXPENSES_IF_EXPOSURE_IS_CLOSED: 'enable_paying_expenses_if_exposure_is_closed',
  IS_EXPENSES_LIMIT_FOR_CLOSED_EXPOSURE_ENABLED: 'is_expenses_limit_for_closed_exposure_enabled',
  EXPENSES_LIMIT_FOR_CLOSED_EXPOSURE: 'expenses_limit_for_closed_exposure',
  ENABLE_PAYING_INDEMNITY_IF_EXPOSURE_IS_CLOSED: 'enable_paying_indemnity_if_exposure_is_closed',
  IS_INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE_ENABLED: 'is_indemnity_limit_for_closed_exposure_enabled',
  INDEMNITY_LIMIT_FOR_CLOSED_EXPOSURE: 'indemnity_limit_for_closed_exposure',
  PREFILL_INDEMNITY_PAYEE_WITH_CLAIMANT: 'prefill_indemnity_payee_with_claimant',
  IS_PREFILL_RESERVE_AMOUNTS_CAPABILITY_ENABLED: 'is_prefill_reserve_amounts_capability_enabled',
  IS_ORG_LEVEL_CONFIGURATION: 'is_org_level_configuration',
  REQUIRE_APPROVAL: 'require_approval_for_every_payment',
  PRESENT_APPROVAL_SCREEN: 'enable_approval_screen',
  ADDITIONAL_APPROVING_USERS: 'additional_approving_user_ids',
  PRESENT_SUBMISSION_SCREEN: 'enable_submission_screen',
  ADDITIONAL_SUBMITTING_USER_IDS: 'additional_submitting_user_ids',
  ALLOW_MULTIPLE_PENDING_PAYMENT_REQUESTS: 'allow_multiple_pending_payment_requests',
  ENABLE_DUPLICATE_PAYMENTS_VERIFICATION: 'enable_duplicate_payments_verification',
  DUPLICATE_PAYMENTS_LEVEL_OF_VERIFICATION: 'duplicate_payments_level_of_verification',
  DUPLICATE_PAYMENTS_ACTION: 'duplicate_payments_action',
  DUPLICATE_PAYMENTS_VERIFICATION_INITIALIZED: 'duplicate_payments_verification_initialized',
  ALLOW_INDEMNITY_PAYMENTS_ONLY_FOR_EXISTING_PROPERTY_OWNER:
    'allow_indemnity_payments_only_for_existing_property_owner',
  INCLUDE_VAT_IN_PAYMENT_SCREEN: 'include_vat_in_payment_screen',
  ENABLE_1099_FIELDS: 'enable_1099_fields',
  IS_EXPENSES_SUB_RESERVES_CONFIG_ENABLED: 'is_expenses_sub_reserves_config_enabled',
  IS_MULTIPLE_SUB_RESERVE_PAYMENTS_ENABLED: 'is_multiple_sub_reserve_payments_enabled',
  SHOULD_DISABLE_PAYMENT_EXCEEDING_RESERVE: 'should_disable_payment_exceeding_reserve',
  ENABLE_UK_BANK_ACCOUNT_VALIDATION: 'enable_uk_bank_account_validation',
  ADJUST_RESERVE_INCLUDES_DEDUCTIBLE: 'adjust_reserve_includes_deductible',
  ADJUST_RESERVE_AFTER_PAYMENT_CANCELLATION: 'adjust_reserve_after_payment_cancellation',
  OFAC_CONFIGURATION_ENABLED: 'ofac_configuration_enabled',
  OFAC_FUZZY_MATCH_ENABLED: 'ofac_fuzzy_match_enabled',
  OFAC_FUZZY_MATCH_SCORE: 'ofac_fuzzy_match_score',
  OFAC_IS_SEND_ADDRESS_FIELDS_ENABLED: 'ofac_is_send_address_fields_enabled',
  IS_ANTICIPATED_AMOUNT_INCLUDED_IN_RECOVERIES_CALC: 'is_anticipated_amount_included_in_recoveries_calc',
  DISABLE_ADJUSTER_MODIFYING_SELF_PAYMENT_LIMITS: 'disable_adjuster_modifying_self_payment_limits',
};
