import React from 'react';

import Editor from '~/components/core/Editor';
import type { DisplayConfigProps } from '~/components/core/Editor/types';
import Text from '~/components/core/TextComponents/Text';
import type { TemplateBuilderEditorInnerComponentProps } from '~/components/GenericTemplates/types';
import { TEMPLATE_BUILDER_FORM_KEYS } from '~/components/GenericTemplates/utils/genericTemplatesUtils';

type CustomDisplayConfig = Omit<DisplayConfigProps, 'suggestions'>;

const BaseGenericTemplatesEditor: React.FC<
  TemplateBuilderEditorInnerComponentProps & {
    titleHeading: string;
    bodyHeading: string;
    titleFormatDisplayConfig?: CustomDisplayConfig;
    bodyFormatDisplayConfig?: CustomDisplayConfig;
  }
> = ({
  handleChange,
  disabled,
  titleHeading,
  titleTemplate,
  bodyHeading,
  bodyTemplate,
  suggestions,
  titleFormatDisplayConfig = {},
  bodyFormatDisplayConfig = {},
}) => {
  return (
    <div className="my-16">
      <div className="my-16">
        <Text variant={Text.VARIANTS.SM} className="my-12">
          {titleHeading}
        </Text>
        <Editor
          className="overflow-auto"
          disabled={disabled}
          onChange={(value) => handleChange(value, TEMPLATE_BUILDER_FORM_KEYS.TITLE_TEMPLATE)}
          autoFocus
          displayConfig={{ ...titleFormatDisplayConfig, suggestions: !!suggestions }}
          content={titleTemplate}
          suggestionOptions={suggestions}
        />
      </div>
      <div className="my-16">
        <Text variant={Text.VARIANTS.SM} className="my-12">
          {bodyHeading}
        </Text>
        <Editor
          className="min-h-[150px] overflow-auto"
          disabled={disabled}
          onChange={(value) => handleChange(value, TEMPLATE_BUILDER_FORM_KEYS.BODY_TEMPLATE)}
          autoFocus
          displayConfig={{
            ...bodyFormatDisplayConfig,
            suggestions: !!suggestions,
          }}
          suggestionOptions={suggestions}
          content={bodyTemplate}
        />
      </div>
    </div>
  );
};

export default BaseGenericTemplatesEditor;
