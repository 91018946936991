import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldArray, Formik } from 'formik';
import { isEmpty, noop } from 'lodash';
import * as Yup from 'yup';

import Button from '~/components/core/Atomic/Buttons/Button';
import IconButton from '~/components/core/Atomic/Buttons/IconButton';
import Typography from '~/components/core/Atomic/Typography';
import CancelButton from '~/components/core/Buttons/CancelButton';
import RadioButtonGroupFormik from '~/components/core/Formik/RadioButtonGroupFormik';
import { yupPhoneValidation } from '~/Utils/phoneValidationUtils';

import CardDialog from '../../../../../CardDialog';
import CheckboxFormik from '../../../../../CheckboxFormik';
import { ArrowIntegerIcon, CalendarEmptyIcon, PlusIcon, TrashIcon } from '../../../../../icons';
import TextFieldFormik, { DatePickerTextFieldFormik, TimePickerTextFieldFormik } from '../../../../../TextFieldFormik';
import { FIELD_IDS } from '../../PhoneConfigurationTab';

import { useStyles } from '../../../../../../assets/styles';
import colors from '../../../../../../assets/colors.module.scss';
import styles from './WorkingHoursConfig.module.scss';

const useCustomStyles = makeStyles(() => ({
  textField: {
    maxWidth: 150,
    '& .MuiIconButton-root': {
      paddingRight: 0,
    },
  },
  datePicker: {
    width: '100%',
    '& .MuiIconButton-root': {
      paddingRight: 5,
    },
  },
}));

const FORM_IDS = {
  SEND_TO_VOICEMAIL_OR_FORWARD_CALL: 'send_to_voicemail_or_forward_call',
  FORWARD_CALL_PHONE_NUMBER: 'forward_call_phone_number',
  BUSINESS_HOURS: 'business_hours',
  ADDITIONAL_NON_WORKING_DAYS: 'additional_non_working_days',
};

const businessHoursDefaultConfig = [
  {
    label: 'Monday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Tuesday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Wednesday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Thursday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Friday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Saturday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
  {
    label: 'Sunday',
    is_work_day: true,
    working_hours_start: null,
    working_hours_end: null,
  },
];

const getVoicemailOrForwardCallValue = (configuration = {}) => {
  if (configuration.should_forward_call) {
    return 'should_forward_call';
  }
  if (configuration.should_send_to_voicemail) {
    return 'should_send_to_voicemail';
  }
  return null;
};

const EditWorkingHoursDialog = ({ configuration = {}, onCancel = noop, onSubmit }) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();

  const handleSubmit = async (values, formikProps) => {
    try {
      const parsedValues = {
        business_hours_configuration_enabled: true,
        business_hours_configuration: {
          should_send_to_voicemail: values[FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL] === 'should_send_to_voicemail',
          should_forward_call: values[FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL] === 'should_forward_call',
          forward_call_phone_number: values[FORM_IDS.FORWARD_CALL_PHONE_NUMBER],
          business_hours: values[FORM_IDS.BUSINESS_HOURS],
          additional_non_working_days: values[FORM_IDS.ADDITIONAL_NON_WORKING_DAYS],
        },
      };
      await onSubmit(parsedValues);
      onCancel();
    } catch {
      formikProps.setSubmitting(false);
    }
  };

  const yupTimeValidation = Yup.string().matches(
    /^([0-1][0-9]|2[0-3]):[0-5][0-9](|:[0-5][0-9])$/,
    'Invalid time supplied'
  );

  const validationSchema = Yup.object().shape({
    [FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL]: Yup.string()
      .oneOf(['should_send_to_voicemail', 'should_forward_call'], 'Required')
      .required('Required')
      .nullable(),
    [FORM_IDS.FORWARD_CALL_PHONE_NUMBER]: yupPhoneValidation.when([FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL], {
      is: 'should_forward_call',
      then: yupPhoneValidation.required('Required'),
    }),
    [FORM_IDS.BUSINESS_HOURS]: Yup.array().of(
      Yup.object().shape({
        index: Yup.number(),
        label: Yup.string(),
        is_work_day: Yup.boolean(),
        working_hours_start: yupTimeValidation.nullable().when('is_work_day', {
          is: true,
          then: yupTimeValidation.required('Required').nullable(),
        }),
        working_hours_end: yupTimeValidation
          .nullable()
          .when(['is_work_day'], {
            is: true,
            then: yupTimeValidation.nullable().required('Required').nullable(),
          })
          .when(['working_hours_start'], {
            is: (val) => !!val,
            then: yupTimeValidation
              .test('is-greater-than-start', 'End time should be after start time', function (value) {
                const { working_hours_start } = this.parent;
                return value > working_hours_start;
              })
              .nullable(),
          }),
      })
    ),
    [FORM_IDS.ADDITIONAL_NON_WORKING_DAYS]: Yup.array().of(
      Yup.object().shape({
        start_date: Yup.date().required('Required').nullable(),
        end_date: Yup.date()
          .min(Yup.ref('start_date'), 'End date must be after or equal to start date')
          .required('Required')
          .nullable(),
      })
    ),
  });

  return (
    <Formik
      initialValues={{
        [FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL]: getVoicemailOrForwardCallValue(configuration),
        [FORM_IDS.FORWARD_CALL_PHONE_NUMBER]: configuration.forward_call_phone_number || '',
        [FORM_IDS.BUSINESS_HOURS]: configuration[FORM_IDS.BUSINESS_HOURS] || businessHoursDefaultConfig,
        [FORM_IDS.ADDITIONAL_NON_WORKING_DAYS]: configuration[FORM_IDS.ADDITIONAL_NON_WORKING_DAYS]
          ? configuration[FORM_IDS.ADDITIONAL_NON_WORKING_DAYS]
          : [
              {
                start_date: null,
                end_date: null,
              },
            ],
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, handleSubmit, values }) => {
        return (
          <CardDialog
            title="Business hours & holidays"
            isDialog
            maxWidth="sm"
            fullWidth
            onClose={onCancel}
            preventClose={isSubmitting}
          >
            <div className={styles.workingHoursRadioGroupWrapper}>
              <div>
                <Typography variant="subtitle1">Outside business hours</Typography>
                <Typography variant="body1">Choose how to handle calls received outside of business hours</Typography>
              </div>
              <RadioButtonGroupFormik
                id={FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL}
                direction="row"
                options={[
                  {
                    text: 'Send to voicemail',
                    optionValue: 'should_send_to_voicemail',
                  },
                  {
                    text: 'Forward to phone number',
                    optionValue: 'should_forward_call',
                  },
                ]}
                variant="subtitle1"
              />
              {values[FORM_IDS.SEND_TO_VOICEMAIL_OR_FORWARD_CALL] === 'should_forward_call' ? (
                <TextFieldFormik id={FORM_IDS.FORWARD_CALL_PHONE_NUMBER} label="Add Phone Number" />
              ) : null}
            </div>
            <div className={styles.workingHoursBusinessDaysWrapper}>
              <FieldArray name="days">
                {() => (
                  <>
                    {values[FORM_IDS.BUSINESS_HOURS].map((day, index) => (
                      <div key={index} className={styles.businessDayRow}>
                        <CheckboxFormik
                          containerClassName={styles.dayCheckbox}
                          id={`${[FORM_IDS.BUSINESS_HOURS]}.${index}.is_work_day`}
                          label={day.label}
                        />
                        <div className={styles.timePickersContainer}>
                          <TimePickerTextFieldFormik
                            setTouchedOnChange
                            className={customClasses.textField}
                            id={`${[FORM_IDS.BUSINESS_HOURS]}.${index}.working_hours_start`}
                            keyboardIcon={<ArrowIntegerIcon size={16} iconColor={colors.inputIcon} />}
                          />
                          <span className={styles.timeTextSeparator}>to</span>
                          <TimePickerTextFieldFormik
                            setTouchedOnChange
                            className={customClasses.textField}
                            id={`${[FORM_IDS.BUSINESS_HOURS]}.${index}.working_hours_end`}
                            keyboardIcon={<ArrowIntegerIcon size={16} iconColor={colors.inputIcon} />}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>
            </div>
            <div className={styles.holidaysWrapper}>
              <Typography variant="subtitle1">Set Holidays</Typography>
              <div className={styles.dateListContainer}>
                <FieldArray name={FORM_IDS.ADDITIONAL_NON_WORKING_DAYS}>
                  {(arrayHelpers) => (
                    <div className={styles.datePickerContainer}>
                      {values[FORM_IDS.ADDITIONAL_NON_WORKING_DAYS].map((day, index) => (
                        <div key={index} className={styles.additionalNonWorkingDayRow}>
                          <DatePickerTextFieldFormik
                            containerClassName={styles.datePickerTextFieldFormikContainer}
                            id={`${FORM_IDS.ADDITIONAL_NON_WORKING_DAYS}.${index}.start_date`}
                            className={customClasses.datePicker}
                            label="Start Date"
                            keyboardIcon={<CalendarEmptyIcon size={16} iconColor={colors.textPrimary} />}
                          />
                          <span className={styles.dateTextSeparator}>to</span>
                          <DatePickerTextFieldFormik
                            containerClassName={styles.datePickerTextFieldFormikContainer}
                            id={`${FORM_IDS.ADDITIONAL_NON_WORKING_DAYS}.${index}.end_date`}
                            className={customClasses.datePicker}
                            label="End Date"
                            keyboardIcon={<CalendarEmptyIcon size={16} iconColor={colors.textPrimary} />}
                          />
                          <IconButton className={styles.trashIconButton} onClick={() => arrayHelpers.remove(index)}>
                            <TrashIcon size={20} iconColor={colors.textPrimary} />
                          </IconButton>
                        </div>
                      ))}
                      <Button
                        className={styles.addDateButton}
                        variant="text"
                        color="primary"
                        startIcon={<PlusIcon iconColor={colors.buttonLink} size={14} />}
                        onClick={() =>
                          arrayHelpers.push({
                            start_date: undefined,
                            end_date: undefined,
                          })
                        }
                      >
                        ADD DATE
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </div>
            </div>
            <div className={classes.buttonsContainer}>
              <CancelButton disabled={isSubmitting} onClick={onCancel} />
              <Button variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
                Save
              </Button>
            </div>
          </CardDialog>
        );
      }}
    </Formik>
  );
};

EditWorkingHoursDialog.propTypes = {
  configuration: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

const WorkingHoursConfigView = ({ twilioConfiguration = {} }) => {
  const workingHoursConfiguration = twilioConfiguration[FIELD_IDS.BUSINESS_HOURS_CONFIGURATION];
  return (
    <>
      <Typography variant="subtitle1">Business hours & holidays</Typography>
      <Typography variant="caption">
        Specify the scheduled business hours when users are available to take direct calls. By default, the lines are
        open 24/7.
      </Typography>
      {twilioConfiguration[FIELD_IDS.BUSINESS_HOURS_CONFIGURATION_ENABLED] && !isEmpty(workingHoursConfiguration) ? (
        <div className={styles.detailsContainer}>
          <div className={styles.detailsItem}>
            <Typography variant="subtitle2" className={styles.detailsItemTitle}>
              Business Hours:
            </Typography>
            <div>
              {workingHoursConfiguration.business_hours.map((day) => (
                <div key={day.index} className={styles.dayDetailsContainer}>
                  <Typography variant="subtitle2" className={styles.itemDetailsLabel}>
                    {day.label}
                  </Typography>
                  <div className={styles.hoursContainer}>
                    {day.is_work_day ? (
                      <>
                        <span className={styles.timeDigits}>{day.working_hours_start}</span>
                        <span>to</span>
                        <span className={styles.timeDigits}>{day.working_hours_end}</span>
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Divider orientation="vertical" flexItem className={styles.detailsDivider} />
          <div className={styles.detailsItem}>
            <Typography variant="subtitle2" className={styles.detailsItemTitle}>
              Outside Business Hours:
            </Typography>
            <div className={styles.outOfOfficeItemContainer}>
              <Typography variant="subtitle2" className={styles.itemDetailsLabel}>
                {workingHoursConfiguration.should_forward_call
                  ? 'Send to Phone:'
                  : workingHoursConfiguration.should_send_to_voicemail
                  ? 'Send to voicemail'
                  : null}
              </Typography>
              {workingHoursConfiguration.should_forward_call && workingHoursConfiguration.forward_call_phone_number ? (
                <span className={styles.forwardCallPhoneNumber}>
                  {workingHoursConfiguration.forward_call_phone_number}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

WorkingHoursConfigView.propTypes = {
  twilioConfiguration: PropTypes.object.isRequired,
};

export { EditWorkingHoursDialog, WorkingHoursConfigView };
