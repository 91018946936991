import React from 'react';

import Editor from '~/components/core/Editor';

import Caption from '../../core/TextComponents/Caption';

import type { EmailConfigurationContentContainerProps } from './emailConfigurationTypes';

const EmailConfigurationInnerPreview: React.FC<EmailConfigurationContentContainerProps> = ({ title, signature }) => {
  return (
    <div className="className">
      <div className="m-12">
        <Caption variant={Caption.VARIANTS.LABEL}>Subject:</Caption>
        <Editor disabled content={title} containerClassName="border-0" />
      </div>
      <div className="m-12">
        <Caption variant={Caption.VARIANTS.LABEL}>Signature:</Caption>
        <Editor disabled containerClassName="border-0" content={signature} />
      </div>
    </div>
  );
};

export default EmailConfigurationInnerPreview;
