import React, { useState } from 'react';
import { Select } from '@material-ui/core';

import { useStyles } from '~/assets/styles';
import CardDialog from '~/components/CardDialog';
import ClaimSearchContainer from '~/components/ClaimSearch';
import Button from '~/components/core/Atomic/Buttons/Button';
import MenuItem from '~/components/core/Atomic/MenuItem';
import type { ExposureModel } from '~/components/exposures/types';
import GenericTemplateContentContainer from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateCard/GenericTemplateContentContainer';
import type { Claim, TemplateFormSubmitValuesProps } from '~/components/GenericTemplates/types';
import { GENERIC_TEMPLATES_ROUTES } from '~/components/GenericTemplates/utils/routes';
import LoadingIndicator from '~/components/LoadingIndicator';
import { useSysconfig } from '~/components/SystemConfiguration/SystemConfigurationScreen';
import useDataFetcher from '~/components/useDataFetcher';
import { useFetchClaim } from '~/Utils/ClaimUtils';

interface GenericTemplatePreviewContainerProps {
  onClose: () => void;
  template: TemplateFormSubmitValuesProps;
}
type handleResultClick = (claimId: number) => void;

export const SelectClaim: React.FC<{ handleResultClick: handleResultClick; claim: Claim }> = ({
  handleResultClick,
  claim,
}) => {
  function handleSelectClaim() {
    handleResultClick(claim.id);
  }

  return (
    <Button color="primary" onClick={handleSelectClaim}>
      Choose claim
    </Button>
  );
};

const GenericTemplatePreviewContainer: React.FC<
  GenericTemplatePreviewContainerProps & { setDialogTitle: React.Dispatch<React.SetStateAction<string>> }
> = ({ template, setDialogTitle }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { organization } = useSysconfig();
  const [claimId, setClaimId] = useState<null | number>(null);
  const [claim, isLoadingClaim, isErrorClaim] = useFetchClaim(claimId);

  const [chosenExposureId, setChosenExposureId] = useState<undefined | ExposureModel['id']>(undefined);
  const classes = useStyles();

  const url =
    claim?.id && template.id
      ? GENERIC_TEMPLATES_ROUTES.GENERATE_FROM_TEMPLATE({
          organizationId: organization.id,
          templateId: template.id,
          claimId: claim.id,
          exposureId: chosenExposureId,
        })
      : '';

  const { data, isLoading, isError } = useDataFetcher(url, {}, !!claim);

  const handleResultClick: handleResultClick = (claimIdFromSearch) => {
    setClaimId(claimIdFromSearch);
  };

  if (!claimId) {
    return (
      <ClaimSearchContainer
        classes={classes}
        SelectComponent={SelectClaim}
        selectComponentProps={{ handleResultClick }}
        label="Choose claim for example"
        shouldOpenClaimInNewTab
        lobs={template.lobs}
        subOrganizationIds={template.sub_organization_ids}
      />
    );
  }

  if (!claim || isLoadingClaim || isLoading || isErrorClaim || isError) {
    return <LoadingIndicator isError={isError || isErrorClaim} isLoading />;
  }

  const shouldDisplayExposureSelect = !template.is_claim_level;
  const coverageKeys = template.coverage_keys;
  const exposures: ExposureModel[] = claim?.exposures || [];

  if (!chosenExposureId && shouldDisplayExposureSelect) {
    setDialogTitle('Choose Exposure');
    const filteredExposures =
      !coverageKeys || coverageKeys.length === 0
        ? exposures
        : exposures.filter((exposure) => coverageKeys.includes(exposure.coverage_type));

    if (filteredExposures.length === 0) {
      return <div>No exposures that fit this template exist on claim {claim.claim_id_display}</div>;
    }

    return (
      <div className="py-32">
        <Select
          onChange={(event) => {
            const value = event.target.value as number;
            setChosenExposureId(value);
          }}
          renderValue={(value) =>
            filteredExposures.find((e) => e.id === value)?.coverage_type_desc || 'Choose Exposure'
          }
          className="w-1/3"
          value={chosenExposureId || ''}
          autoWidth
          displayEmpty
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            disableAutoFocus: true,
          }}
        >
          {filteredExposures?.map((exposure) => (
            <MenuItem key={exposure.id} value={exposure.id}>
              {exposure.coverage_type_desc} - {exposure.involved_property?.display_name}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
  setDialogTitle(`Example template based on claim ${claim?.claim_id_display}`);
  return <GenericTemplateContentContainer titleTemplate={data?.title_template} bodyTemplate={data?.body_template} />;
};

const GenericTemplatePreviewContainerCardDialogWrapper: React.FC<GenericTemplatePreviewContainerProps> = (props) => {
  const [dialogTitle, setDialogTitle] = useState<string>('Choose Claim to generate template example');
  return (
    <CardDialog isDialog title={dialogTitle} onClose={props.onClose} fullWidth maxWidth="md">
      <GenericTemplatePreviewContainer {...props} setDialogTitle={setDialogTitle} />
    </CardDialog>
  );
};
export default GenericTemplatePreviewContainerCardDialogWrapper;
