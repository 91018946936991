const DAMAGE_ASSESSMENT_TYPES = {
  default: 'General Damage Assessment',
  liability: 'Liability Dashboard',
  pip: 'PIP Dashboard',
  multiple_damages: 'Multiple Damages',
  medical: 'Medical Treatments',
  worldtrip_demo_line: 'WorldTrips Demo',
  fastcover_demo: 'Fastcover Demo',
} as const;

export default DAMAGE_ASSESSMENT_TYPES;
