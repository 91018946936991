import React from 'react';

import BaseGenericTemplatesEditor from '~/components/GenericTemplates/GenericTemplatesConfiguration/GenericTemplateBuilder/BaseGenericTemplatesEditor';
import type { TemplateBuilderEditorInnerComponentProps } from '~/components/GenericTemplates/types';

const NotificationGenericTemplatesEditor: React.FC<TemplateBuilderEditorInnerComponentProps> = ({
  handleChange,
  disabled,
  titleTemplate,
  bodyTemplate,
  suggestions,
}) => {
  return (
    <BaseGenericTemplatesEditor
      titleHeading="Notification Title"
      bodyHeading="Notification Body"
      suggestions={suggestions}
      bodyTemplate={bodyTemplate}
      titleTemplate={titleTemplate}
      disabled={disabled}
      handleChange={handleChange}
    />
  );
};

export default NotificationGenericTemplatesEditor;
